import { Stack } from "@mui/material";
import { FaroButton } from "../../button/faro-button";
import { FaroTooltip } from "../../tooltip/tooltip";

export type AnnotationEditorButtonsProps = {
  /**
   * Text to show in the confirm button
   *
   * @default Edit
   */
  confirmButtonText?: string;

  /** Function called when the cancel button is clicked */
  onCancelButtonClick?(): void;

  /** Function called when the confirm button is clicked */
  onConfirmButtonClick?(): void;

  /** if `true`, a spinner will be shown and the buttons will be disabled*/
  disabled: boolean;

  /** if `true`, the confirm button will be disabled */
  isConfirmButtonDisabled?: boolean;

  /** Tooltip text for the confirm button */
  confirmButtonTooltip?: string;
};

/**
 * @returns The buttons to use to confirm or cancel annotation creation or edition
 */
export function AnnotationEditorButtons({
  confirmButtonText = "Save",
  onCancelButtonClick,
  onConfirmButtonClick,
  disabled,
  isConfirmButtonDisabled,
  confirmButtonTooltip,
}: AnnotationEditorButtonsProps): JSX.Element {
  return (
    <Stack direction="row" justifyContent="end">
      <FaroButton
        variant="ghost"
        dark
        onClick={onCancelButtonClick}
        disabled={disabled}
      >
        Cancel
      </FaroButton>
      <FaroTooltip title={confirmButtonTooltip}>
        <FaroButton
          dark
          onClick={onConfirmButtonClick}
          isLoading={disabled}
          disabled={disabled || isConfirmButtonDisabled}
        >
          {confirmButtonText}
        </FaroButton>
      </FaroTooltip>
    </Stack>
  );
}
